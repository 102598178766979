import './App.css';
import FirstPage from './FirstPage';

function App() {
  return (
    <div className='section'>
      <FirstPage />
    </div>
  );
}

export default App;
