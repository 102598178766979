import { IoIosMail, IoIosCall } from 'react-icons/io'
import "./FirstPage.css"

export default function FirstPage() {
    return (
        <div className="background">
            <div className="amazing">Something amazing</div>
            <div className="soon">COMING SOON</div>
            <br /><br />
            <div className="companyName">nishkarsh.co</div>
            <br /><br />
            <div className="stayTitle">Stay Connected</div>
            <div className='contactDiv'>
                <div className='center'>
                    <IoIosMail size={30}
                        color='#29d97b' className='icon'/>                   
                    <div className='stay'>info@nishkarsh.co</div>
                </div>
                <div className='contactSeparator'></div>
                <div className='center'>                  
                    <IoIosCall size={30}
                        color='#29d97b' className='icon'/>                         
                    <div className='stay'>7718088932, 8140021838</div>
                </div>
            </div>
        </div>
    )
}